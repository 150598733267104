<template>
  <v-container
    fluid
    grid-list-xl
  >
    {{ $t('home.error') }}
  </v-container>
</template>

<script>
  export default {
    name: 'Error',
  }
</script>
